a > #post-photo-scroller {
  display: inline-block;
}

#post-photo-scroller .img {
  height: 100%;
  width: min-content;
  border: solid black 2px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

#post-photo-scroller img {
  object-fit: cover;
  height: 100%;
  width: auto;
}
