#root {
  position: relative;
}
body {
  font-size: 14px;
  font-family: Verdana, sans-serif;
  margin: 0;
}

header {
  background-color: black;
  color: white;
}

footer {
  margin: 10px;
  font-style: italic;
}

main {
  background-color: #f9f5ff;
  height: 100%;
  position: relative;
}

/* GRID-BASED LAYOUT */
body #root {
  display: grid;
  min-height: 100vh;
  margin: 0;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
}

p {
  margin-top: 0.5em;
}

header {
  grid-row: 1;
  grid-column: 1;
  height: 12vw;
  min-height: 50px;
  max-height: 80px;
}

main {
  grid-row: 2;
  grid-column: 1;
  padding: 0 0;
}

#dropdown {
  position: absolute;
  z-index: 10;
  width: 30vw;
  padding: 10px;
  background-color: #000;
  font-size: 3vw;
  color: white;
  border-style: none solid solid none;
  border-color: gray;
  border-width: 1px;
}

#stream {
  margin-left: auto;
  margin-right: auto;
  max-width: 750px;
  width: 100%;
  height: 100%;
  position: relative;
}

footer {
  grid-row: 3;
  grid-column: 1;
}

.post {
  padding-bottom: 10px;
  padding-top: 6px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: #4e5b82 2px 2px 8px 0px;
  margin-bottom: 40px;
  background-color: white;
}

.read-section {
  padding: 5px;
}

.post .post-header {
  text-align: center;
  margin: 5px;
}

.post h2 {
  font-size: 4.5vw;
}

.img {
  position: relative;
}

.location-text {
  color: black;
  position: absolute;
  bottom: -10px;
  left: 0px;
  box-sizing: border-box;
  background-color: #d0d0d075;
  width: 100%;
  padding: 3px;
  font-size: 16px;
  font-style: italic;
  text-shadow: 1px 1px #7d7d7d;
}

.square-image {
  width: 100%;
}

.post {
  position: relative;
}

.comments-container h3 {
  font-size: 16px;
  background-color: #d0d0d075;
  padding: 4px;
  font-size: 16px;
  font-style: italic;
  text-shadow: 1px 1px #7d7d7d;
}

.submit-button {
  color: white;
  background-color: #231dce;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #231dce;
  cursor: pointer;
}

.submit-button:hover {
  color: gray;
}

.more-text {
  position: absolute;
  bottom: -3px;
  right: 10px;
  cursor: pointer;
  border: solid;
  border-radius: 15px;
  border-width: 1px;
  border-color: grey;
  background-color: #e6e4e4;
  font-size: 15px;
  padding: 5px;
  padding-left: 9px;
  padding-right: 9px;
  box-shadow: #bdbdbc 2px 2px 8px 0px;
}

.more-text:hover {
  color:gray;
}

.read-section.not-expanded:hover p {
  color: gray;
}

#subtitle {
  font-style: italic;
}

.add-comment {
  font-size: 14px;
  padding-left: 5px;
}

input {
  background: none;
  border: 1px solid grey;
}

input:focus {
  background: white;
  border: 1px solid grey;
}

input, textarea {
  background-color: whitesmoke;
  font-family: Verdana, sans-serif;
  border-color: gray;  
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 3px;
}

textarea {
  resize: none;
  width: 70%;
}

.add-comment label {
  margin-right: 3px;
  display: inline-table;
  vertical-align: top;
  width: 75px;
  text-align: right;
}

.comment h4 {
  font-size: 14px;
  margin-bottom: 1px;
}

.time {
  font-size: 12px;
  font-style: italic;
  color: #808080;
  margin-left: 10px;
}

.content {
  font-size: 13px;
  color: #4e4e4e;
}

/**
 * A div that needs to be expanded by a Read More button will have this class.
 */
.read-more-container {
  position: relative;
  text-align: justify;
  line-height: 1.3em;
}

.title-text {
  display: inline;
}

.star-post-button.not-starred{
  border: solid;
  border-radius: 15px;
  border-width: 1px;
  border-color: grey;
  background-color: transparent;
  font-size: 13px;
  padding-right: 5px;
  padding-top: 5px;
  box-shadow: #bdbdbc 2px 2px 8px 0px;
  cursor: pointer;
}

.star-post-button.starred{
  border: none;
  background-color: transparent;
  font-size: 13px;
}

.stars {
  font-size: 11px;
  text-align: right;
  padding-right: 10px;
}

#star-image {
  margin-left: 2px;
  padding-bottom: 5px;
  vertical-align: middle;
  width: 25px;
  height: 25px;
}

.num-stars{
  padding-left: 10px
}

header h1 {
  font-size: 4vw;
}

header a:hover {
  cursor: pointer;
}

/**
 * Makes links in header not appear different
 */
header a:link, #dropdown a:link {
  text-decoration: inherit;
  color: inherit;
}

/**
 * Makes links in header not appear different
 */
header a:visited, #dropdown a:visited {
  text-decoration: inherit;
  color: inherit;
}

#dropdown a:hover {
  color: gray;
}

header {
  display: flex;
}

#humburger {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 55px;
  max-width: 12%;
  flex-basis: auto;
  flex-grow: 1;
  padding-left: 1.5%;
  padding-right: 1.8%;
  padding-bottom: 0.5%;
  padding-top: 0.5%;
}

#header-text {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

#header-text h1 {
  width: 100%;
}

.bar {
  box-sizing: border-box;
  height: 15%;
  margin: auto;
  width: 100%;
  background-color: white;
}

.link-block {
  padding-bottom: 10px;
}

@media screen and (min-width:1000px) {
  header h1 {
    font-size: 45px;
  }

  .post h2 {
    font-size: 40px;
  }

  #humburger {
    max-width: 120px;
  }

  #instagram-img img{
    max-width: 35px; 
  }

}

@media only screen and (max-width:600px) {
  #dropdown {
    width: 55vw;
  }

  header h1 {
    font-size: 3.5vw;
}

  #dropdown .link-block {
    font-size: 6vw;
    
  }

  #instagram-img img {
    max-width: 30px;
  }

}

button.hide {
  display: none;
}

#app {
  display: contents;
}

iframe {
  width: 100%;
}

iframe, .img {
  margin-top: .5em;
  margin-bottom: .5em;
}

.loading {
  text-align: center;
  padding: 20px;
}

.submit-button {
  background-color: #464584;
  color: white;
  border-radius: 7px;
  font-size: 15px;
  padding: 5px;
  font-weight: bold;
  cursor:pointer;
}

h3.comments-header {
  background-color: #464584;
  color: white;
  text-shadow: 1px 1px #2b2b2b;
}

#instagram-img {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;

}

#instagram-img img {
  width: 100%;
  margin-right: 10px;
  border: solid;
  border-width: 1px;
  border-radius: 10px;
  background-color: white; 
}

#taxeedee-link {
  text-decoration: underline;
}

.post-header-link {
  text-decoration: none;
  color: black;
  display: block;
}

.post-header-link .post-header:hover {
  cursor: pointer;
}

.post-header-link .post-header:hover .title-text {
  color: gray;
}

.clickable {
  cursor: pointer;
}
