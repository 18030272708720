a > .map-post {
  display: inline-block;
}

.map-post {
  width: 10vw;
  min-width: 7em;
  height: 100%;
  position: relative;
  border: solid black 1px;
  box-sizing: border-box;
  overflow: hidden;
}

.map-post .title {
  padding: 1vh;
  position: absolute;
  background-color: #808080ad;
  z-index: 1;
  white-space: initial;
}

.map-post img {
  height: 100%;
  position: absolute;
  left: -100%;
  right: -100%;
  top: -100%;
  bottom: -100%;
  margin: auto;
  width: auto;
}

.map-post.active .title {
  background-color: #6d78ffb8;
}

.map-post.active {
  border: solid #e9f0f1 5px;
}

.map-post .button {
  position: absolute;
  bottom: 20%;
  width: 100%;
  text-align: center;
  z-index: 2;
  background-color: #ffffffa6;  
  font-size: 100%;
  color: black;
}