#map-panel {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 50% 25% 25%;
  grid-template-columns: 100%;
  position: absolute;
}

#map-panel #map {
  grid-column: 1;
  position: relative;
}

#map-panel #posts {
  grid-column: 1;
  position: relative;
}

#map-panel #post-photo-scroller {
  grid-column: 1;
  z-index: 2;
  position: relative;
}

#map-panel #post-photo-scroller.active {
  grid-row: 3;
}

#map-panel #post-photo-scroller.inactive {
  grid-row: 2;
}

#map-panel #posts.active {
  grid-row: 2;
}

#map-panel #posts.inactive {
  grid-row: 3;
}

#map-panel #map.inactive {
  grid-row: 1 / 3;
}

#map-panel #map.active {
  grid-row: 1 / 2;
}